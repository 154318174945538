/** @format */

import React, { useEffect, useState } from "react";
import unmintCardImg from "../../../../../assets/images/card/marketInfo.png";
import cardImg from "../../../../../assets/images/teleImage/brgCard.png";
import MintCard_P from "../../../../../assets/images/card/MintCard_P.png";
import marketImg from "../../../../../assets/images/card/marketImg.png";
import aptosLogo from "../../../../../assets/images/card/aptosLogo.png";
import style from "./cardStyle.module.scss";
import CollectionsCard from "./collectionsCard";
import Countdown from "react-countdown";
import {
	getCollectionList,
	getInfoCardMinted,
	getListCardReadyToMint,
	getMarkePlace,
	requestMinCard,
	requestSubmitMinCard,
} from "../../../../../services/accountApiService";
import {
	CardMinted,
	CardWateMintItemModel,
	CardWateMintModel,
} from "../../../../../commonsUi/modals/Card/cardWaitMint";
import { encodeJwt } from "../../../../../utils/extension";
import {
	useAccount,
	usePublicClient,
	useSwitchChain,
	useWriteContract,
} from "wagmi";
import { stavaxAccount } from "../../../../../connectStavax";
import {
	MINT_CARD_CONTRACT_ADDRESS_BASE,
	abi,
} from "../../../../../ABI/mintCardAbi";
import CardMintedModal from "../../../../../commonsUi/modals/CardMintedModal";
import { devnetClient } from "../../../../../core/constants";
import {
	feePayer,
	getLocalKeylessAccount,
} from "../../../../../utils/aptos/aptosStorage";
import { getAptosClient } from "../../walletConnect";
import {
	getUserOwnedAptogotchis,
	MARKET_PLACE_CONTRACT,
	MODULE_ADDRESS,
} from "../../../../../aptosConfig";
import LoadingCommon from "../../../../../commonsUi/CommonUI/LoadingApp";
import ModalBurnCard from "../../../../../commonsUi/modals/ModalBurnCard";

const CardPage: React.FC = React.memo(() => {
	const [cardMint, setCardMint] = useState<CardWateMintItemModel[] | null>(
		null
	);
	const [isBought, setBought] = useState(false);
	const [cardMinted, setCardMinted] = useState<CardMinted | null>(null);
	const [isShowCardMinted, setShowCardMinted] = useState(false);
	const [isShowLoading, setShowLoading] = useState(false);
	const [idCollectionShow, setIdCollectionShow] = useState(false);
	const [cardMintedInfo, setCardMintedInfo] = useState<MarketInfo | null>(
		null
	);
	const [collectionList, setCollectionList] = useState<
		CollectionModel[] | null
	>(null);
	const [isShowAlert, setShowAlert] = useState(false);

	const [listNft, setListNft] = useState<MarketResponse | null>(null);

	const requestGetAllCollection = async () => {
		const rest: any = await getCollectionList();
		console.log({ rest });
		setCollectionList(rest.data);
		console.log({ collectionList });
	};
	function stringToVectorU8(str: string): number[] {
		return Array.from(str).map((char) => char.charCodeAt(0));
	}
	useEffect(() => {
		requestGetAllCollection();
	}, [isShowCardMinted]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowAlert(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isShowAlert]);

	const mintCard = async (
		img: string,
		message: number,
		cardType: number,
		signature: number,
		cardName: string,
		id: number
	) => {
		try {
			const uri = stringToVectorU8(img);
			const transaction = await devnetClient.transaction.build.simple({
				withFeePayer: true,
				sender: getAptosClient!.accountAddress,
				data: {
					function: `${MODULE_ADDRESS}::slime_nft_slime::create_aptoslime`,
					functionArguments: [
						cardName,
						cardType,
						signature,
						message,
						uri,
						MODULE_ADDRESS,
					],
				},
			});
			const feePayerAuthenticator =
				devnetClient.transaction.signAsFeePayer({
					signer: feePayer,
					transaction,
				});
			const senderAuthenticator = devnetClient.transaction.sign({
				signer: getAptosClient!,
				transaction,
			});
			const committedTransaction =
				await devnetClient.transaction.submit.simple({
					transaction,
					senderAuthenticator: senderAuthenticator!,
					feePayerAuthenticator: feePayerAuthenticator,
				});
			const response = await devnetClient.waitForTransaction({
				transactionHash: committedTransaction.hash,
			});

			const cardMinted = await getUserOwnedAptogotchis();
			console.log({ cardMinted });

			const value = {
				card_id: cardType,
				id_card_mint: id,
				token_id: cardMinted.token_data_id,
			};
			const payLoadData = await encodeJwt(value);
			await requestSubmitMinCard({ value: payLoadData });

			const dataMinted: any = await getInfoCardMinted(cardType);
			setCardMintedInfo(dataMinted.data);
			console.log({ dataMinted });
			setShowLoading(false);
			console.log({ response });
			setShowCardMinted(true);
			setTimeout(() => {
				setBought(true);
				requestGetListCollection();
			}, 2000);
		} catch (e) {
			setShowLoading(false);
			console.log({ e });
		}
	};

	const requestGetListCollection = async () => {
		const res: any = await getListCardReadyToMint();
		const data = res.data?.map((card: any) => ({
			...card,
			CanMint: true,
		}));
		console.log({ res });
		setCardMint(data);
	};

	const changeStatusMint = (id: number) => {
		setCardMint((prevCards) =>
			prevCards!.map((card) =>
				card.Id === id ? { ...card, CanMint: false } : card
			)
		);
	};

	const mintCardFunc = async (idCard: number) => {
		try {
			setShowLoading(true);
			const value = {
				card_id: idCard,
			};
			const payLoadData = await encodeJwt(value);
			const res: any = await requestMinCard({ value: payLoadData });
			console.log({ res });
			const mintedCard: CardMinted = {
				img: res.data.image_link,
				name: res.data.card_name,
			};
			setCardMinted(mintedCard);
			mintCard(
				res.data.image_link,
				res.data.Message,
				res.data.type,
				res.data.Signature,
				res.data.card_name,
				res.data.id
			);
		} catch (e) {
			setShowLoading(false);
		}
	};
	console.log("Khanh");

	const StatsCard = () => {
		return (
			<div className={style.statsCard}>
				<div className={style.statsItem}>
					<span>Total Volume:</span>
					<span>
						<strong>{`${
							cardMintedInfo?.total_volume ?? ""
						} APT`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Floor Price:</span>
					<span>
						<strong>{`${cardMintedInfo?.floor_price} APT`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Highest Price:</span>
					<span>
						<strong>{`${cardMintedInfo?.highest} APT`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Listed:</span>
					<span>
						<strong>{`${cardMintedInfo?.listed} %`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Unique Owner:</span>
					<span>
						<strong>{`${cardMintedInfo?.unique_own} `}</strong>
					</span>
				</div>
			</div>
		);
	};

	const RenderMintCard = () => {
		return (
			<div className={style.mintcardBody}>
				<div
					className={`${style.mintCardM} active-btn`}
					onClick={() => {
						if (cardMint![0]!.CanMint) {
							mintCardFunc(cardMint![0].CardId);
						}
					}}
				>
					<img
						src={MintCard_P}
						alt=""
						className={style.mintCardImg}
					/>
					<Countdown
						className={style.mintCardText}
						date={Number(
							new Date(cardMint![0].ExpiredTimeMint).getTime()
						)}
						onComplete={() => {
							if (!cardMint![0].CanMint) {
								changeStatusMint(cardMint![0].Id);
								requestGetListCollection();
								return;
							}
						}}
					/>
					<p className={style.cardValue}>{`${cardMint?.length}/3`}</p>
				</div>
			</div>
		);
	};

	const requestMarketPlace = async () => {
		const res: any = await getMarkePlace(1);
		setListNft(res);
		console.log(res);
	};

	useEffect(() => {
		requestMarketPlace();
		requestGetListCollection();
	}, [isShowLoading]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setBought(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isBought]);

	const RenderHeader = () => {
		return (
			<div className={style.main1}>
				<div className={style.headerStyle}>
					<img
						className={style.cardStyle}
						src={cardImg}
						alt="cardImg"
					/>
					<img
						className={style.tagCardImg}
						src={unmintCardImg}
						alt="cardImg"
					/>
					<div className={style.bodyHeader}>
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "space-between",
								padding: "0 16px",
								alignItems: "center",
								alignContent: "center",
								marginTop: "20px",
							}}
						>
							<div>
								<div className={style.statsItem}>
									<span className={style.label}>
										Total Volume:
									</span>
									<span className={style.value}>
										<strong>{`${listNft?.data.summary_market.total_volume} APT`}</strong>
									</span>
								</div>
								<div className={style.statsItem}>
									<span className={style.label}>
										Total Supply:
									</span>
									<span className={style.value}>
										<strong>{`${listNft?.data.summary_market.total_supply}`}</strong>
									</span>
								</div>
								<div className={style.statsItem}>
									<span className={style.label}>
										Unique Owner:
									</span>
									<span className={style.value}>
										<strong>{`${listNft?.data.summary_market.unique_own}`}</strong>
									</span>
								</div>
							</div>
							<img
								src={marketImg}
								className="active-btn"
								alt="marketImg"
								width={100}
								onClick={() => {
									setShowAlert(true);
								}}
								height={100}
							/>
						</div>
						<img
							src={aptosLogo}
							alt="aptosLogo"
							height={20}
							style={{
								marginLeft: "95px",
								marginTop: "20px",
							}}
							width={160}
						/>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div className={style.main2}>
			<div className={style.main}>
				{isShowCardMinted && (
					<CardMintedModal
						img={cardMinted!.img}
						onClose={() => {
							setShowCardMinted(false);
							setIdCollectionShow(true);
						}}
					>
						<StatsCard />
					</CardMintedModal>
				)}
				{isShowAlert && (
					<div className={style.notice1}>{`Coming soon`}</div>
				)}
				<RenderHeader />
				{collectionList && collectionList?.length > 0 && (
					<CollectionsCard collectionList={collectionList!} />
				)}
			</div>
			<LoadingCommon
				visible={isShowLoading}
				width={80}
				height={80}
				isLeft10={true}
			/>
			{idCollectionShow && (
				<ModalBurnCard
					idCardSelect={cardMintedInfo!.collection_id}
					onClose={() => setIdCollectionShow(false)}
				/>
			)}
			{cardMint && cardMint.length > 0 && <RenderMintCard />}
		</div>
	);
});
export default CardPage;
