/** @format */

import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import GameButton from "../../commonButton/gameButton/GameButton";
import style from "./index.module.scss";

type CardMintedModalType = {
	img: string;
	onClose: () => void;
	children?: React.ReactNode;
};

export default function CardMintedModal({
	img,
	onClose,
	children,
}: CardMintedModalType) {
	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<p className={style.textgot}>You've got</p>
				<img
					src={img}
					alt="img"
					width={200}
					style={{
						margin: "40px 0",
					}}
				/>
				{children}
				<p className={style.checkCollectionStyle} onClick={onClose}>
					Check collection
				</p>
			</div>
		</div>
	);
}
