/** @format */

import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import arowBottomImg from "../../../../../assets/images/teleImage/arowBottomImg.png";
import bscLogo from "../../../../../assets/images/bscLogo.png";
import baseLogo from "../../../../../assets/images/baseLogo.png";
import arbLogo from "../../../../../assets/images/arbLogo.png";

export interface OptionSelectChain {
	value: string;
	label: string;
	logo?: string;
	chainId: number;
}

type SelectChainType = {
	onSelect: ({ value }: any) => void;
};

const SelectChain = ({ onSelect }: SelectChainType) => {
	const options: OptionSelectChain[] = [
		{
			value: "Smart Chain",
			label: "BNB Smart Chain",
			logo: bscLogo,
			chainId: 56,
		},
		{ value: "Arbitrum", label: "Arbitrum", logo: arbLogo, chainId: 42161 },
		{ value: "BASE", label: "BASE", logo: baseLogo, chainId: 8453 },
	];

	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] =
		useState<OptionSelectChain | null>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const toggleDropdown = () => setIsOpen(!isOpen);

	const handleOptionClick = (option: OptionSelectChain) => {
		onSelect(option);
		setSelectedOption(option);
		setIsOpen(false);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div
			style={{
				display: "flex",
				justifySelf: "center",
				alignSelf: "center",
				width: "100%",
				maxWidth: "380px",
				alignItems: "center",
				alignContent: "center",
				justifyContent: "center",
			}}
		>
			<div className={styles.dropdown} ref={dropdownRef}>
				<button
					onClick={toggleDropdown}
					className={styles.dropdownToggle}
				>
					{selectedOption?.logo && (
						<img
							src={selectedOption?.logo}
							alt="selectNetworkImg"
							width={30}
						/>
					)}
					{selectedOption ? selectedOption.label : "Choose network"}
					<p></p>
					<img src={arowBottomImg} alt="arowBottomImg" width={16} />
				</button>
				<ul
					className={`${styles.dropdownMenu} ${
						isOpen ? styles.open : ""
					}`}
				>
					{options.length > 0 &&
						options.map((option) => (
							<div style={{
                                marginTop:"8px"
                            }}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										alignContent: "center",
										justifyContent: "start",
										paddingLeft: "10px",
									}}
								>
									<img
										src={option?.logo}
										alt="selectNetworkImg"
										width={26}
										height={26}
									/>
									<li
										key={option.value}
										onClick={() =>
											handleOptionClick(option)
										}
										className={styles.dropdownItem}
									>
										{option.label}
									</li>
								</div>
								<div className={styles.dividerStyle}></div>
							</div>
						))}
				</ul>
			</div>
		</div>
	);
};

export default SelectChain;
