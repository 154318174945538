/** @format */

import React from 'react';
import gameButtonImg from '@/assets/images/gameButtonCommon.png';
import gameButtonImgDsb from '@/assets/images/gameButtonCommonDisable.png';
import styles from './gameButtonStyle.module.scss';

type GameButtonType = {
	onTap? : () => void;
	title: string;
	isGrayColor?: boolean;
	isDisable?: boolean;
	customStyle?: React.CSSProperties;
};

export default function GameButton({
	onTap,
	title,
	isGrayColor = false,
	isDisable = false,
	customStyle,
}: GameButtonType) {
	return (
		<button
			id="button"
			onClick={onTap}
			style={{
				background: 'transparent',
				...customStyle,
			}}
			className="active-btn"
		>
			{isDisable ? (
				<div className={styles.bodyDisable}>
					<span className={styles.childrenStyleDisable}>{title}</span>
				</div>
			) : (
				<div className={isGrayColor === false ? styles.body : styles.body1}>
					<span className={styles.childrenStyle}>{title}</span>
				</div>
			)}
		</button>
	);
}
