import style from "./index.module.scss";
import React, { useEffect } from "react";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { aptos, MODULE_ADDRESS } from "../../../aptosConfig";
import { encodeJwt } from "../../../utils/extension";
import {
	checkedInRequest,
	requestUpdateProfile,
} from "../../../services/accountApiService";
import { getRandomString } from "../../../utils/constants/pages";
import aptosLogo from "../../../assets/images/common/aptosLogo.png";
import USDTlOGO from "../../../assets/images/teleImage/USDTLoginIn.png";
import stavaxImg from "../../../assets/images/teleImage/USDTLoginIn.png";
import titleStavax from "../../../assets/images/common/titlecheckinAptos.png";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import { stavaxAccount } from "../../../connectStavax";
type ReachUSDTModalType = {
	onClose: () => void;
};

export default function ReachUSDTModal({
	onClose,
}: ReachUSDTModalType) {
	return (
		<div className={style.main}>
			<div className={`${style.popup}`}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						height: "100%",
						justifyContent: "center",
						flexDirection: "column",
					}}
				>
					<img
					className={style.iconStyle}
						src={USDTlOGO}
						alt="aptosLogo"
						style={{
							width: "56%",
						}}
					></img>
					<p className={style.titleStyle}>
					Reach $5 USDT to withdraw
					</p>
					<CircleButton
						title="Got it"
						onTap={() => {
							onClose();
						}}
					/>
				</div>
			</div>
			<div className={`${style.modalOverlay}`}></div>
		</div>
	);
}
