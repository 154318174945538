import React, { useEffect, useState } from "react";
import styles from "./welcome_chest.module.scss";
import textTap from "../../../../../../assets/images/teleImage/arrow_bottom_img.png";
import iconOpenChest from "../../../../../../assets/images/teleImage/openCheest.png";
import iconChest from "../../../../../../assets/images/teleImage/Chest.png";
import logo from "../../../../../../assets/images/logoWebGame.png";
import { apiRewardTspc } from "../../../../../../services/accountApiService";

enum Visibility {
	Visible = "visible",
	Hidden = "hidden",
}
interface IWelcomeChest {
	fncShowPreLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const WelcomeChest = ({ fncShowPreLoading }: IWelcomeChest) => {
	const [countTab, setCountTab] = useState(0);
	const [statusLoading, setStatusLoading] = useState<Visibility | undefined>(
		Visibility.Visible
	);

	const handleTabChest = () => setCountTab((prev) => prev + 1);

	const handleApiRewardTspc = async () => {
		await apiRewardTspc();
		localStorage.setItem("__showPreLoad", "1");
	};

	useEffect(() => {
		if (countTab >= 3) {
			handleApiRewardTspc();
			setTimeout(() => {
				setStatusLoading(Visibility.Hidden);
				fncShowPreLoading(false);
			}, 2000);
		}
	}, [countTab]);

	return (
		<div
			className={styles.wrapper}
			style={{
				visibility: statusLoading,
			}}
		>
			<div className={styles.logo}>
				<img src={logo} alt="logo" />
			</div>
			<div className={styles.group__chest}>
				{countTab >= 3 ? (
					<img
						className={styles.chest}
						src={iconOpenChest}
						width={"400px"}
						height={"auto"}
						alt="icon chest"
						onClick={handleTabChest}
					/>
				) : (
					<>
						<img
							className={styles.textTap}
							src={textTap}
							width={"282px"}
							height={"auto"}
							alt="icon text to tap"
						/>
						<img
							className={styles.chest}
							src={iconChest}
							width={"322px"}
							height={"auto"}
							alt="icon chest"
							onClick={handleTabChest}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default WelcomeChest;
