/** @format */

import { ThreeDots, Vortex } from "react-loader-spinner";
import styles from "./loadingStyle.module.scss";

const LoadingCommon = ({
	visible = true,
	width = 40,
	height = 40,
	isLeft10 = false,
	isPaddingLeft = false,
}) => {
	return (
		<div>
			{visible && (
				<div
					className={
						isPaddingLeft == true
							? styles.modalOverlayPaddingLeft
							: styles.modalOverlay
					}
				>
					<div className={isLeft10 ? styles.modal1 : styles.modal}>
						<ThreeDots
							visible={visible}
							height={height}
							width={width}
							ariaLabel="three-dots-loading"
							radius="9"
							wrapperStyle={{}}
							wrapperClass="vortex-wrapper"
							// colors={["red", "green", "blue", "yellow", "orange", "purple"]}
							color="orange"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default LoadingCommon;
