// components/StarRating.js
import React from "react";
import startImg from "../../../assets/roll/card/start.png";
type StarRatingType = {
	count: number;
};
export default function StarRating({ count }: StarRatingType) {
	return (
		<div >
			{Array.from({ length: count }, (_, index) => (
				<img
					key={index}
					src={startImg}
					alt={`star-${index}`}
					width={16}
					height={15}
				/>
			))}
		</div>
	);
}
