import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/globalStyle.scss";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import reportWebVitals from "./reportWebVitals";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import TeleAppPage from "./components/page/TeleAppPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./main";
import { WagmiProvider } from "wagmi";
import { configMainNet } from "./wagmiConfig";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import CallbackPage from "./CallbackPage";
const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();
const wallets = [new PetraWallet()];

const router = createBrowserRouter([
	{
		path: "/",
		element: <TeleAppPage />,
	},
	{
		path: "/callback",
		element: <CallbackPage />,
	},
]);
root.render(
		<Provider store={store}>
			<WagmiProvider config={configMainNet}>
				<TonConnectUIProvider
					manifestUrl="https://api-telegame.slimerevolution.com/tonconnect-manifest.json"
					actionsConfiguration={{
						twaReturnUrl: "https://t.me/Slime_Revolution_bot/app",
					}}
				>
					<AptosWalletAdapterProvider
						plugins={wallets}
						autoConnect={true}
					>
						<QueryClientProvider client={queryClient}>
							{<Main />}
						</QueryClientProvider>
					</AptosWalletAdapterProvider>
				</TonConnectUIProvider>
			</WagmiProvider>
		</Provider>
);

reportWebVitals();
