/** @format */

import ChoosePlanFarming from "../choosePlanFarming";
import Countdown from "react-countdown";
import style from "./index.module.scss";
import iconUSDT from "../../../assets/roll/modals/USDT.png";
import { WITHDRAW_CONTRACT_ADDRESS_BASE, abi } from "../../../ABI/withDrawUSDT";
import {
	changeStatusWithDraw,
	withDrawUsd,
} from "../../../services/accountApiService";
import { decodeJwt, encodeJwt } from "../../../utils/extension";
import ButtonClaim from "../../buttons/buttonClaim";
import {
	useAccount,
	usePublicClient,
	useSwitchChain,
	useWriteContract,
} from "wagmi";
import { stavaxAccount } from "../../../connectStavax";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";

type SrawUsdtType = {
	onClosePopup: () => void;
	title: string;
	des: string;
};

export default function ModalWithDrawUSDT({
	onClosePopup,
	title,
	des,
}: SrawUsdtType) {
	const account = useAccount();
	const { switchChainAsync } = useSwitchChain();
	const { writeContractAsync } = useWriteContract();
	const publicClient = usePublicClient();

	const withDrawUSDT = async (id: number, signature: string) => {
		if (account?.address === undefined) {
			const session = await stavaxAccount.connect();
			const botURL = stavaxAccount.getTgBotWebAppURL(session!).value;
		} else {
			try {
				const isWrongChain = account?.chainId !== 8453;
				if (isWrongChain) {
					await switchChainAsync({ chainId: 8453 });
				}
                stavaxAccount.openTgBot();
				const hash = await writeContractAsync({
					abi,
					address: WITHDRAW_CONTRACT_ADDRESS_BASE,
					functionName: "withdrawUSDT",
					args: [5000000, id, signature],
				});
				requestChangeStatusWithDraw(id, 1);
				onClosePopup();
				await publicClient?.waitForTransactionReceipt({
					hash: hash,
				
				});
			} catch (e) {
				console.log({ e });
				requestChangeStatusWithDraw(id, 0);
			}
		}
	};
	const requesWithDrawUSDT = async () => {
		try {
			const res = await withDrawUsd();
			const resDecode: any = await decodeJwt(res.data);
			let modifiedString =
				resDecode.data.signature.substring(0, 5) +
				resDecode.data.signature.substring(10);
			withDrawUSDT(resDecode.data.idClaim, modifiedString);
		} catch (e) {
			console.log({ e });
		}
	};

	const requestChangeStatusWithDraw = async (idTran: number, type: any) => {
		try {
			const value = {
				type: type,
				idTran: idTran,
			};
			const payLoadData = await encodeJwt(value);
			const res = await changeStatusWithDraw({ value: payLoadData });
			const resDecode = await decodeJwt(res.data);
		} catch (e) {
			console.log({ e });
		}
	};
	return (
		<ChoosePlanFarming
			onClose={onClosePopup}
			iconPopup={iconUSDT}
			customStyle={{
				width: "230px",
				height: "auto",
				top: "-30%",
			}}
		>
			<div className={style.popup__usdt_wrapper}>
				<h2 className={style.popup__usdt_title}>{title}</h2>
				<p className={style.popup__usdt_description}>{des}</p>
				<div className={style.popup__usdt_list_button}>
					<div>
						<div
							style={{
								marginTop: "50px",
							}}
						>
							<CircleButton
								isGrayColor={false}
								title="Withdraw $5 USDT"
								onTap={() => {
									requesWithDrawUSDT();
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</ChoosePlanFarming>
	);
}
