/** @format */

import React, { useEffect, useState } from "react";
import TSPCIcon from "../../../../../assets/images/ranking/TSPCIcon.png";
import titleImage from "../../../../../assets/images/teleImage/title.png";
import styles from "./rankingStyle.module.scss";
import rankImg from "../../../../../assets/images/ranking/rank_3.png";
import usdtImg from "../../../../../assets/images/teleImage/usdtImg.png";
import rank2Img from "../../../../../assets/images/ranking/rank_1.png";
import rank3Img from "../../../../../assets/images/ranking/rank_2.png";
import itemRankImg from "../../../../../assets/images/teleImage/itemRankImg.png";
import titleRankingImg from "../../../../../assets/images/teleImage/TitleRanking.png";
import dividerImg from "../../../../../assets/images/teleImage/dividerImg.png";
import spcImg from "../../../../../assets/images/ranking/SPC.png";
import inviteMore from "../../../../../assets/images/teleImage/invitemore.png";
import EnergyRefer from "../../../../../assets/images/teleImage/EnergyRefer.png";
import iconCopy from "../../../../../assets/images/iconCopy.png";
import ChoosePlanFarming from "../../../../../commonsUi/modals/choosePlanFarming";
import { writeClipboardText } from "../../../../../utils/function/text";
import backImg from "../../../../../assets/images/ranking/bacnk.png";
import nextImg from "../../../../../assets/images/ranking/next.png";
import inFoImg from "../../../../../assets/images/ranking/Info1.png";

import Rank from "../../../../../assets/images/ranking/group.png";
import Rank1 from "../../../../../assets/images/ranking/group1.png";
import Rank2 from "../../../../../assets/images/ranking/group2.png";
import Rank3 from "../../../../../assets/images/ranking/group3.png";
import Rank4 from "../../../../../assets/images/ranking/group4.png";
import Rank5 from "../../../../../assets/images/ranking/group5.png";
import Rank6 from "../../../../../assets/images/ranking/group6.png";
import { requestGetRanking } from "../../../../../services/accountApiService";
import ModalInfoRanking from "../../../../../commonsUi/modals/InfoRanking";
import Countdown from "react-countdown";
const rankData: {
	[key: string]: { [key: number]: number };
} = {
	1: { 1: 500, 2: 1000, 3: 2000, 4: 6000, 5: 15000, 6: 30000, 7: 45500 },
	2: { 1: 375, 2: 750, 3: 1500, 4: 4500, 5: 11250, 6: 22500, 7: 34125 },
	3: { 1: 225, 2: 450, 3: 900, 4: 2700, 5: 6750, 6: 13500, 7: 20475 },
	"4-5": { 1: 150, 2: 300, 3: 600, 4: 1800, 5: 4500, 6: 9000, 7: 13650 },
	"6-10": { 1: 120, 2: 240, 3: 480, 4: 1440, 5: 3600, 6: 7200, 7: 10920 },
	"11-20": { 1: 80, 2: 160, 3: 320, 4: 960, 5: 2400, 6: 4800, 7: 7280 },
};
export default function RankingPage() {
	const [showCopy, setShowCopy] = useState(true);
	const [isShowInvite, setShowInvite] = useState(false);
	const [userIdAccount, setUserId] = useState(null);
	const [rankingList, setRankingList] = useState<DataItemRanking | null>(
		null
	);
	const [userRanking, setUserRanking] = useState<RankingItem | null>(null);
	const [indexSelected, setIndexSelected] = useState(0);
	const [isShowInfo, setShowInfo] = useState(false);
	interface RankingProps {
		image: string;
		title: string;
		amount: number;
	}

	const [dataList, setDataList] = useState<RankingProps[]>([]);

	const [imageList, setImageList] = useState<string[]>([]);

	useEffect(() => {
		const images = [Rank, Rank4, Rank6, Rank3, Rank5, Rank1, Rank2];

		setImageList(images);
	}, []);

	function getRankValue(rank: number, group: number): number | string {
		let rankKey: string;

		if (rank === 1) {
			rankKey = "1";
		} else if (rank === 2) {
			rankKey = "2";
		} else if (rank === 3) {
			rankKey = "3";
		} else if (rank >= 4 && rank <= 5) {
			rankKey = "4-5";
		} else if (rank >= 6 && rank <= 10) {
			rankKey = "6-10";
		} else if (rank >= 11 && rank <= 20) {
			rankKey = "11-20";
		} else {
			return "Invalid rank";
		}

		return rankData[rankKey]?.[group] ?? "Invalid group";
	}

	useEffect(() => {
		const generateData = (): RankingProps[] => {
			const data: RankingProps[] = [];
			for (let i = 1; i <= 10; i++) {
				data.push({
					image: getImage(i),
					title: `Item ${i}`,
					amount: Math.floor(Math.random() * 100),
				});
			}
			return data;
		};

		setDataList(generateData());
	}, []);

	const handleGetRanking = async () => {
		const res: any = await requestGetRanking();
		const updatedRankingList = res.data;
		for (let i = 0; i <= 6; i++) {
			if (updatedRankingList.ranking[i]) {
				let currentLength = updatedRankingList.ranking[i].length;
				if (currentLength < 20) {
					const itemsToAdd = 20 - currentLength;
					for (let j = 0; j < itemsToAdd; j++) {
						updatedRankingList.ranking[i].push({
							user_name: "-",
							gold: "0",
						});
					}
				} else if (currentLength > 20) {
					updatedRankingList.ranking[i] = updatedRankingList.ranking[
						i
					].slice(0, 20);
				}
			}
		}
		setRankingList(updatedRankingList);
		setUserRanking(res.data.user_ranking);
		if (res.data.user_ranking && res.data.user_ranking.group != null) {
			console.log(
				"res.data.user_ranking.group",
				res.data.user_ranking.group
			);
			setIndexSelected(res.data.user_ranking.group - 1);
		}
		console.log(indexSelected);
	};

	useEffect(() => {
		handleGetRanking();
	}, []);
	const getImage = (index: number) => {
		switch (index) {
			case 0:
				return rankImg;
			case 1:
				return rank2Img;
			case 2:
				return rank3Img;
			case 3:
				return itemRankImg;
			default:
				return itemRankImg;
		}
	};
	function formatNumber(num: number) {
		if (num >= 1e12) {
			return (num / 1e12).toFixed(0) + "T";
		} else if (num >= 1e9) {
			return (num / 1e9).toFixed(0) + "B";
		} else if (num >= 1e6) {
			return (num / 1e6).toFixed(2) + "M";
		} else if (num >= 1e3) {
			return (num / 1e3).toFixed(0) + "k";
		} else {
			return num.toString();
		}
	}

	interface BuildItemProps {
		index: number;
		totalBattle: number;
		tspc: number;
		name: string;
		isDisplayDivider?: boolean;
	}

	const BuildItem: React.FC<BuildItemProps> = ({
		index,
		totalBattle,
		name,
		tspc,
		isDisplayDivider = true,
	}) => {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div className={styles.bodyItem}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							columnGap: "16px",
							width: "40%",
						}}
					>
						{index < 3 ? (
							<img
								src={getImage(index)}
								alt="rankImg"
								height={28}
							/>
						) : (
							<div className={styles.imgMoreThan3}>
								{index + 1}
							</div>
						)}

						<span>{`${truncate(name)}`}</span>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							columnGap: "4px",
							width: "80px",
							backgroundColor: "rgba(0, 0, 0, 0.20)",
							borderRadius: "16px",
							alignContent: "center",
							height: "26px",
							justifyContent: "center",
						}}
					>
						<img src={spcImg} alt="spcImg" height={20} />
						<span>
							{formatNumber(totalBattle).toLocaleUpperCase()}
						</span>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							columnGap: "4px",
							width: "80px",
							backgroundColor: "rgba(0, 0, 0, 0.20)",
							borderRadius: " 16px",
							alignContent: "start",
							height: "26px",
							justifyContent: "start",
						}}
					>
						<img src={TSPCIcon} alt="spcImg" height={26} />
						<span className={styles.styleText}>
							{tspc.toLocaleString()}
						</span>
					</div>
				</div>
				{isDisplayDivider && (
					<img
						src={dividerImg}
						alt="dividerImg"
						height={5}
						style={{
							width: "96%",
							height: "5",
							marginLeft: "7px",
							marginTop: "16px",
						}}
					/>
				)}
			</div>
		);
	};
	interface TimeLeft {
		days: number;
		hours: number;
		minutes: number;
		seconds: number;
		expired?: boolean;
	}
	const calculateTimeLeft = (): TimeLeft => {
		const targetDate = new Date("2024-09-15T00:00:00");
		const now = new Date();
		const difference = targetDate.getTime() - now.getTime();

		if (difference <= 0) {
			return { days: 0, hours: 0, minutes: 0, seconds: 0, expired: true };
		}

		return {
			days: Math.floor(difference / (1000 * 60 * 60 * 24)),
			hours: Math.floor(
				(difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			),
			minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
			seconds: Math.floor((difference % (1000 * 60)) / 1000),
		};
	};

	const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			const newTimeLeft = calculateTimeLeft();
			setTimeLeft(newTimeLeft);

			if (newTimeLeft.expired) {
				clearInterval(timer); // Dừng bộ đếm khi thời gian đã hết
			}
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCopy(false);
		}, 1000);
		return () => clearTimeout(timer);
	}, [showCopy]);
	const truncate = (str: string): string => {
		if (str.length > 7) {
			return str.substring(0, 7) + "...";
		}
		return str;
	};
	const getTextByIndex = (index: number): string => {
		const values: { [key: number]: string } = {
			0: "7,8,9",
			1: "10,11,12",
			2: "13,14,15",
			3: "16,17",
			4: "18",
			5: "19",
			6: "20",
		};

		return values[index] || "";
	};
	return (
		<div className={styles.body}>
			{isShowInfo && (
				<ModalInfoRanking
					onClosePopup={() => {
						setShowInfo(false);
					}}
					indexSelected={indexSelected}
				/>
			)}
			<div className={styles.headerStyle}>
				<img
					src={backImg}
					className={styles.backStyle}
					alt="backImg"
					width={35}
					height={35}
					onClick={() => {
						if (indexSelected === 0) {
							setIndexSelected(imageList.length - 1);
							return;
						}
						setIndexSelected(indexSelected - 1);
					}}
				/>
				<img
					src={imageList![indexSelected]}
					style={{
						width: "90%",
						marginLeft: "5%",
					}}
					alt={`slime-${imageList![indexSelected]}`}
				/>
				<div className={styles.startStyle}> </div>
				<img
					src={inFoImg}
					alt="inFoImg"
					width={20}
					className={styles.infoStyle}
					onClick={() => setShowInfo(true)}
				/>
				{!timeLeft.expired && (
					<div className={styles.timeLeftStyle}>
						Timeleft:{" "}
						{`${timeLeft.days}days ${timeLeft.hours}hours 
						${timeLeft.minutes}mins`}
					</div>
				)}
				<p
					className={styles.islandLevelStyle}
				>{`Island Level: ${getTextByIndex(indexSelected)}`}</p>
				<img
					className={styles.nextStyle}
					width={35}
					height={35}
					src={nextImg}
					alt="nextImg"
					onClick={() => {
						if (imageList!.length - 1 === indexSelected) {
							setIndexSelected(0);
							return;
						}
						setIndexSelected(indexSelected + 1);
					}}
				/>
			</div>
			<p className={styles.lastUpdateStyle}>
				The event has ended, any new plays won’t be counted.
			</p>
			{/* {rankingList && rankingList!.ranking[indexSelected].length > 0 ? (
				<div className={styles.mainItem}>
					{rankingList?.ranking[indexSelected]?.map((e, index) => (
						<div key={index} style={{ marginTop: "16px" }}>
							<BuildItem
								index={index}
								name={e?.user_name ?? ""}
								totalBattle={e.gold ?? "0"}
								tspc={Number(
									getRankValue(e.island_level, indexSelected + 1)
								)}
							/>
						</div>
					))}
				</div>
			) : (
				<div className={styles.nodataStyle}>
					<p>
						There's no data here. Earn more gold to become the first
						user to claim the total reward.
					</p>
				</div>
			)}
			{userRanking && userRanking.group === indexSelected + 1 && (
				<div className={styles.myRankingStyle}>
					<div className={styles.bodyItem}>
						<div
							style={{
								display: "flex",
							}}
						>
							<div className={styles.imgMoreThan3}>
								{userRanking.ranking}
							</div>
							<div className={styles.bodyItem}>
								<span>{`${truncate(
									userRanking.user_name!
								)}`}</span>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								columnGap: "4px",
								width: "90px",
								backgroundColor: "rgba(0, 0, 0, 0.20)",
								borderRadius: "16px",
								alignContent: "center",
								height: "26px",
								justifyContent: "center",
							}}
						>
							<img src={spcImg} alt="spcImg" height={20} />
							<span>
								{formatNumber(
									userRanking.gold
								).toLocaleUpperCase()}
							</span>
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								columnGap: "4px",
								width: "80px",
								backgroundColor: "rgba(0, 0, 0, 0.20)",
								borderRadius: " 16px",
								alignContent: "start",
								height: "26px",
								justifyContent: "start",
							}}
						>
							<img src={TSPCIcon} alt="spcImg" height={26} />
							<span className={styles.styleText}>
								{Number(
									getRankValue(
										userRanking.island_level,
										indexSelected + 1
									)
								).toLocaleString()}
							</span>
						</div>
					</div>
				</div>
			)} */}
			<p className={styles.nodataStyle}>
				The next event start from 3:00 18th Sep to 3:00 17th Oct.
			</p>
			{isShowInvite && (
				<ChoosePlanFarming
					iconPopup={EnergyRefer}
					onClose={() => setShowInvite(false)}
				>
					<div className={styles.wrapper}>
						<p className={styles.confirmPurchaseTitle}>
							Invite friends to earn more energy
						</p>
						<div>
							<div className={styles.copyMainStyle}>
								<img
									onClick={() => {
										setShowCopy(true);
										writeClipboardText(
											`https://telegram.me/Slime_Revolution_bot?start=${userIdAccount} \n\nPlay-to-Airdrop! Start your journey to earn tSPC. The more tSPC you get, the bigger your Airdrop. Earn now!`
										);
									}}
									src={iconCopy}
									alt="iconCopy"
									style={{
										width: "30px",
										height: "30px",
										marginTop: "10px",
										position: "absolute",
										right: "4px",
									}}
								/>
								<div className={styles.classRefer}>
									<p className={styles.referLinkStyle}>
										Your referral link
									</p>
									<p
										className={styles.desReferLink}
									>{`https://telegram.me/Slime_Revolution_bot?start=${userIdAccount}`}</p>
								</div>
								{showCopy && (
									<div className={styles.copyStyle}>
										Copied
									</div>
								)}
							</div>
						</div>
						{/* <GameButton
                            title="Invite friends"
                            onTap={() => {
                                setShowInvite(false);
                            }}
                        /> */}
					</div>
				</ChoosePlanFarming>
			)}
		</div>
	);
}
