/** @format */

import { createSlice } from '@reduxjs/toolkit';

export const dayRewards = createSlice({
	name: 'dayRewards',
	initialState: {
		value: null,
	},
	reducers: {
		setIndexDayRewards: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setIndexDayRewards } = dayRewards.actions;
