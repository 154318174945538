import React, { useEffect, useState } from "react";
import CommonPriceButton from "../../../../../../commonsUi/CommonPriceButton";
import styles from "./index.module.scss";
import { collapseAddress } from "../../../../../../core/utils";
import dividerImg from "../../../../../../assets/images/teleImage/dividerImg.png";
import {
	getCardListed,
	unSellCard,
} from "../../../../../../services/accountApiService";
import UpdateCardPriceModal from "../../../../../../commonsUi/modals/UpdateCardPrice";
import { i } from "@aptos-labs/ts-sdk/dist/common/accountAddress-Lv-GSihU";
import { encodeJwt } from "../../../../../../utils/extension";
import { devnetClient } from "../../../../../../core/constants";
import { getAptosClient } from "../../../walletConnect";
import {
	getUserOwnedAptogotchis,
	MARKET_PLACE_CONTRACT,
	MODULE_ADDRESS,
} from "../../../../../../aptosConfig";
import { APTOS_COIN } from "@aptos-labs/ts-sdk";
import { feePayer } from "../../../../../../utils/aptos/aptosStorage";
import CircleButton from "../../../../../../commonsUi/commonButton/gameButton/CircleButton/CircleButton";
import ChoosePlanFarming from "../../../../../../commonsUi/modals/choosePlanFarming";
import Slime from "../../../../../../assets/roll/Slime.svg";
import LoadingCommon from "../../../../../../commonsUi/CommonUI/LoadingApp";
export default function ListedCard() {
	const [cardListeds, setCardListeds] = useState<ResCardListed | null>(null);
	const [isShowUpdatePrice, setShowUpdatePrice] = useState(false);
	const [isUpdateSuccess, setUpdateStatus] = useState(false);
	const [isUnlisSuccess, setUnListSuccess] = useState(false);
	const [isShowLoading, setShowLoading] = useState(false);
	const [indexSelected, setIndexSelected] = useState(0);
	const [isShowAlert, setShowAlert] = useState("");
	const getCarListed = async () => {
		const resCardListed: any = await getCardListed();
		setCardListeds(resCardListed);
		console.log({ resCardListed });
	};

	const unListCard = async (newTokenId: string) => {
		const value = {
			card_id: cardListeds!.data[indexSelected].CardId,
			token_id: cardListeds!.data[indexSelected].TokenIdCard,
			new_token_id: newTokenId,
		};

		const payLoadData = await encodeJwt(value);
		await unSellCard({ value: payLoadData });
	};

	const unListCardContract = async () => {
		setShowLoading(true);
		try {
			const transaction = await devnetClient.transaction.build.simple({
				withFeePayer: true,
				sender: getAptosClient!.accountAddress,
				data: {
					function: `${MODULE_ADDRESS}::${MARKET_PLACE_CONTRACT}::unlist`,
					typeArguments: [APTOS_COIN],
					functionArguments: [
						cardListeds!.data[indexSelected].MarketTokenId,
					],
				},
			});
			const feePayerAuthenticator =
				devnetClient.transaction.signAsFeePayer({
					signer: feePayer,
					transaction,
				});
			const senderAuthenticator = devnetClient.transaction.sign({
				signer: getAptosClient!,
				transaction,
			});
			const committedTransaction =
				await devnetClient.transaction.submit.simple({
					transaction,
					senderAuthenticator: senderAuthenticator!,
					feePayerAuthenticator: feePayerAuthenticator,
				});
			const response = await devnetClient.waitForTransaction({
				transactionHash: committedTransaction.hash,
			});
			const cardMinted = await getUserOwnedAptogotchis();
			await unListCard(cardMinted.token_data_id);
			await getCarListed();
			console.log(`Committed transaction: ${response}`);
			setShowAlert("Unlist Successful");
			setShowLoading(false);
		} catch (e) {
			setShowLoading(false);
			console.log({ e });
		}
	};

	useEffect(() => {
		getCarListed();
	}, []);
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowAlert("");
		}, 2000);
		return () => clearTimeout(timer);
	}, [isShowAlert]);

	return (
		<div className={styles.main}>
			{cardListeds &&
				cardListeds?.data.length > 0 &&
				cardListeds!.data.map((e, index) => (
					<div>
						<div className={styles.card} key={index}>
							<img
								src={e.Card.CardImage}
								alt="Item"
								className={styles.cardImage}
							/>
							<div className={styles.cardDetails}>
								<p className={styles.id}>
									<span>ID Card:</span>{" "}
									<span>{e.CardId}</span>
								</p>
								<p className={styles.price}>
									<span>Current Price:</span>{" "}
									<span>{e.Price} APT</span>
								</p>
								<p
									className={styles.editPriceStyle}
									onClick={() => {
										setShowUpdatePrice(true);
									}}
								>
									<span>Edit Price</span>
								</p>
							</div>
							<div className={styles.buyStyle}>
								<CommonPriceButton
									onClick={() => {
										setIndexSelected(index);
										setUnListSuccess(true);
									}}
								>
									<span className={styles.priceStyle}>
										Unlist
									</span>
								</CommonPriceButton>
							</div>
						</div>
						<img
							src={dividerImg}
							alt="dividerImg"
							style={{
								width: "98%",
							}}
						/>
					</div>
				))}

			{isShowUpdatePrice && (
				<UpdateCardPriceModal
					card_market_id={
						cardListeds!.data[indexSelected].MarketTokenId
					}
					onUpdate={async () => {
						setShowAlert("Updated Successful");
						await getCarListed();
					}}
					cardId={1}
					onClose={() => setShowUpdatePrice(false)}
					iconPopup={cardListeds!.data[indexSelected].Card.CardImage}
				/>
			)}

			{isUnlisSuccess && (
				<ChoosePlanFarming
					iconPopup={Slime}
					onClose={() => setUnListSuccess(false)}
				>
					<div
						style={{
							marginTop: "35%",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							rowGap: "16px",
						}}
					>
						<p className={styles.listSuccessfulStyle}>
							Confirm unlisting
						</p>
						<CircleButton
							title="ConFirm"
							onTap={() => {
								setUnListSuccess(false);
								unListCardContract();
							}}
						/>
					</div>
				</ChoosePlanFarming>
			)}

			<LoadingCommon
				visible={isShowLoading}
				width={80}
				height={80}
				isLeft10={true}
			/>

			{isShowAlert !== "" && (
				<div className={styles.notice1}>{isShowAlert}</div>
			)}
		</div>
	);
}
