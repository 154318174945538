import style from "./index.module.scss";
import React, { useEffect } from "react";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { aptos, MODULE_ADDRESS } from "../../../aptosConfig";
import { encodeJwt } from "../../../utils/extension";
import { checkedInRequest } from "../../../services/accountApiService";
import { getRandomString } from "../../../utils/constants/pages";
import aptosLogo from "../../../assets/images/common/aptosLogo.png";
import titleAptos from "../../../assets/images/common/titleCheckin.png";
import stavaxImg from "../../../assets/images/common/aptosImg.png";
import titleStavax from "../../../assets/images/common/titlecheckinAptos.png";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import {
	useAccount,
	usePublicClient,
	useSwitchChain,
	useWriteContract,
} from "wagmi";
import { stavaxAccount } from "../../../connectStavax";
import { parseEther } from "viem";
import { BattleSkip_CONTRACT_ADDRESS_BASE,abi } from "../../../ABI/baseAbiTestNet";
type ConfirmCheckinModalType = {
	onClose: () => void;
	noConnect: () => void;
	statusCheckin: (status: boolean) => void;
};

export default function ConfirmCheckinModal({
	onClose,
	statusCheckin,
	noConnect,
}: ConfirmCheckinModalType) {
	const { account, signAndSubmitTransaction } = useWallet();
	const accountWagmi = useAccount();
	const publicClient = usePublicClient();
	const { switchChainAsync } = useSwitchChain();
	const { writeContractAsync } = useWriteContract();
	const handleVisibilityChange = () => {
		if (document.visibilityState === "visible") {
			hasCheckedInToday();
		}
	};
	const dailyCheckIn = async () => {
		if (accountWagmi?.address === undefined) {
			const session = await stavaxAccount.connect();
			console.log({ session });
			const botURL = stavaxAccount.getTgBotWebAppURL(session!).value;
			console.log({ botURL });
		} else {
			try {
				const isWrongChain = accountWagmi?.chainId !== 8453;
				if (isWrongChain) {
					await switchChainAsync({ chainId: 8453 });
				}
				stavaxAccount.openTgBot();
				const hash = await writeContractAsync({
					abi,
					address: BattleSkip_CONTRACT_ADDRESS_BASE,
					functionName: "mint",
					value: parseEther("0"),
				});
				await requestCheckedIn(accountWagmi?.address);
				var iframe: any = document.getElementById("isLandIframe");
				iframe.contentWindow.postMessage({ type: "CHECKEDIN", text: "" }, "*");
				await publicClient?.waitForTransactionReceipt({
					hash: hash,
				});
				onClose();
				statusCheckin(true);
			} catch (e: any) {
				onClose();
				statusCheckin(false);
			}
		}
	};
	// CHECKIN DAILY
	const checkinDaily = async () => {
		if (account?.address === undefined) {
			noConnect();
		} else {
			try {
				const response = await signAndSubmitTransaction({
					sender: account?.address,
					data: {
						function: `${MODULE_ADDRESS}::CheckInDaiLySlime::mint`,
						functionArguments: [],
					},
				});
				try {
					await aptos.waitForTransaction({
						transactionHash: response.hash,
					});
					requestCheckedIn(account?.address);
				} catch (error) {
					hasCheckedInToday();
				}
			} catch (e) {
				hasCheckedInToday();
			}
		}
	};

	const requestCheckedIn = async (account:any) => {
		try {
			const value = {
				address_smart_wallet: account,
			};
			const payLoadData = await encodeJwt(value);
			await checkedInRequest({
				value: payLoadData + getRandomString(10),
			});
		} catch (e) {}
	};

	// HAS CHECKEDIN TODAY
	const hasCheckedInToday = async () => {
		if (!account) return [];
		try {
			const isCheckedIn: any = await aptos.view({
				payload: {
					function: `${MODULE_ADDRESS}::CheckInDaiLySlime::get_last_checkin_time`,
					typeArguments: [],
					functionArguments: [account.address],
				},
			});
			if (isCheckedIn[0]) {
				const timestamp = isCheckedIn[0] * 1000;
				const date = new Date(timestamp);
				const now = new Date();
				const diffInMinutes =
					(now.getTime() - date.getTime()) / 1000 / 60;
				if (Math.abs(diffInMinutes) <= 30) {
					var iframe: any = document.getElementById("isLandIframe");
					iframe?.contentWindow?.postMessage(
						{ type: "CHECKEDIN", text: "" },
						"*"
					);
					requestCheckedIn(account.address);
					statusCheckin(true);
				}
			} else {
				statusCheckin(false);
			}
		} catch (e: any) {
			console.log({ e });
		}
	};
	useEffect(() => {
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener(
				"visibilitychange",
				handleVisibilityChange
			);
		};
	}, []);

	return (
		<div className={style.main}>
			<div className={`${style.popup}`}>
				<img
					src={buttonCancel}
					width={36}
					height={38}
					alt="button cancel"
					className={style.button__close}
					onClick={onClose}
				/>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						height: "100%",
						justifyContent: "center",
						flexDirection: "column",
					}}
				>
					<img src={stavaxImg} alt="aptosLogo" width={90}></img>
					<img
						src={titleStavax}
						alt="aptosLogo"
						style={{
							width: "86%",
							margin: "20px 0px",
							marginBottom: "36px",
						}}
					></img>
					<CircleButton
						title="Check in"
						onTap={() => {
							dailyCheckIn();
						}}
					/>
				</div>
			</div>
			<div className={`${style.modalOverlay}`}></div>
		</div>
	);
}
