 export interface UserInforData {
	message: string;
	status: number;
	data: {
		SpcInAcc: string;
		SpcInChest: string;
		Crystal: number;
		Usdt: string;
		invitedCount: number;
		actort: {
			Wallet: any; 
			ExpireTime: any; 
			UserId: number;
			Username: string;
			UDID: any; 
			UserData: {
				IpAddress: string;
			};
		};
	};
	options: {};
	success: boolean;
	iat: number;
}
export const getUserInforFromJSON = (json:string) : UserInforData => {
    return JSON.parse(json);
}
