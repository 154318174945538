/** @format */

import React, { useEffect } from "react";
type IsLandComponentType = {
	visible: boolean;
	isMarginBT: boolean;
	setIsClient: React.Dispatch<React.SetStateAction<boolean>>;
	// other props...
};

export default function IsLandComponent({
	visible,
	isMarginBT,
	setIsClient,
}: IsLandComponentType) {
	useEffect(() => {
		// setIsClient(true);
	}, []);
	return (
		<div
		// style={{
		// 	maxHeight: isMarginBT ? "calc(100vh - 66px)" : "100vh",
		// 	marginBottom: isMarginBT ? "66px" : "0px",
		// }}
		>
			<p>{}</p>
			{localStorage.getItem("_gameAccessToken") != null && (
				<iframe
					title="Island Game"
					id="isLandIframe"
					style={{
						width: "100%",
						height: isMarginBT ? "calc(100vh - 66px)" : "90vh",
					}}
					src={`${
						process.env.REACT_APP_ISLANDS_LINK
					}?access_token=${localStorage.getItem(
						"_gameAccessToken"
					)}&user_id=${localStorage.getItem("_userId")}?time=1`}
				></iframe>
			)}
		</div>
	);
}
