import { createConfig, http } from "wagmi";
import { injected, metaMask, walletConnect } from "wagmi/connectors";
import {
	base,
	mainnet,
	sepolia,
	baseSepolia,
	bsc,
	arbitrum,
} from "wagmi/chains";
import { avalanche, avalancheFuji } from "wagmi/chains";
export const configMainNet = createConfig({
	chains: [base, baseSepolia, avalanche, avalancheFuji, bsc, arbitrum],
	connectors: [
		injected(),
		metaMask(),
		walletConnect({
			projectId: "e6b8be1e206c3561ec914bbba97f456a",
			showQrModal: false,
			metadata: {
				name: "Slime Revolution",
				description: "Demo description",
				url: "https://slimerevolution.com/",
				icons: ["https://avatars.githubusercontent.com/u/37784886"],
			},
		}),
	],
	transports: {
		// [bscTestnet.id]: http(
		//     "https://data-seed-prebsc-1-s1.bnbchain.org:8545"
		// ),
		[bsc.id]: http(),
		[avalanche.id]: http(),
		[avalancheFuji.id]: http(),
		[baseSepolia.id]: http(),
		[base.id]: http(),
		[arbitrum.id]: http(),
	},
});
