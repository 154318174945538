import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import App from './App';
import TeleAppPage from './components/page/TeleAppPage';
import PaymentPage from './components/page/Payment';
import CallbackPage from './CallbackPage';
import HomePage from './HomePage';


const Main = () => {
    return (
        <Router>
          <Routes>
            <Route path="/" element={<TeleAppPage />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/callback" element={<CallbackPage/>} />
            <Route path="/home" element={<HomePage/>} />
          </Routes>
        </Router>
      );
};

export default Main;
