/** @format */

import { createSlice } from '@reduxjs/toolkit';

export const coinData = createSlice({
	name: 'coinValue',
	initialState: {
		value: null,
	},
	reducers: {
		setCoinData: (state: any, action: any) => {
			const newItem = action.payload;
			state.value = {
				SCE: newItem.sce ?? 0,
				SRG: newItem.srg ?? 0,
				BNB: newItem.bnb ?? 0,
				USDT: newItem.usdt ?? 0,
			} as { SCE: number; SRG: number; BNB: number; USDT: number };
		},
	},
});

export const { setCoinData } = coinData.actions;
