import React, { useEffect, useState } from "react";
import paymentSuccess from "../../../assets/images/metamaskLogo.png";
import style from "./index.module.scss";
import GameButton from "../../../commonsUi/commonButton/gameButton/GameButton";
import { decodeJwt, encodeJwt } from "../../../utils/extension";
import {
	checkedInRequest,
	updateSmartWallet,
} from "../../../services/accountApiService";
import {
	useAccount,
	useBalance,
	useDisconnect,
	usePublicClient,
	useSwitchChain,
	useWriteContract,
} from "wagmi";
import { parseEther } from "viem";
import {
	SHOP_WALLET_CONTRACT_ADDRESS_BASE,
	abi,
	abiARB,
	SHOP_WALLET_CONTRACT_ADDRESS_BRB,
	abiBSC,
	SHOP_WALLET_CONTRACT_ADDRESS_BSC,
} from "../../../ABI/shopWallet";
import { useConnect } from "wagmi";
import { useLocation } from "react-router-dom";
import SelectChain, {
	OptionSelectChain,
} from "../TeleAppPage/components/QuestComponent/SelectChain";

export default function PaymentPage() {
	const [showCheckIn, setShowCheckIn] = useState<string | null>();
	const accountWagmi = useAccount();
	const { switchChainAsync } = useSwitchChain();
	const { writeContractAsync } = useWriteContract();
	const publicClient = usePublicClient();
	const { connect, connectors } = useConnect();
	const [initata, setInitData] = useState<any>();
	const [isPaymentSuccess, setPaymentSuccess] = useState(false);
	const location = useLocation();
	const [selectedOption, setSelectedOption] =
		useState<OptionSelectChain | null>(null);

	const requestCheckedIn = async (account: any) => {
		try {
			const value = {
				address_smart_wallet: account,
				wallet_type: "Metamask",
			};
			const payLoadData = await encodeJwt(value);
			await updateSmartWallet({
				value: payLoadData,
			});
		} catch (e) {}
	};

	const ethBalance = useBalance({
		address: accountWagmi?.address,
	});
	const initData = async () => {
		const params = new URLSearchParams(location.search);
		const urlRes: any = params.get("value");
		console.log({ urlRes });
		const dataDecode: any = await decodeJwt(urlRes);
		localStorage.setItem("_gameAccessToken", dataDecode?.userToken);
		setInitData(dataDecode);
		console.log({ dataDecode });
	};
	useEffect(() => {
		initData();
	}, []);

	console.log({ ethBalance });

	useEffect(() => {
		if (accountWagmi.address === undefined) {
			connect({ connector: connectors[1] });
		} else {
			requestCheckedIn(accountWagmi.address);
		}
	}, [accountWagmi.address]);

	const buyItem = async (id: number, usdtPrice: number) => {
		try {
			const bnbUsdtPriceResponse = await fetch(
				`https://api.binance.com/api/v3/ticker/price?symbol=${
					selectedOption!.chainId === 56 ? "BNBUSDT" : "ETHUSDT"
				}`
			);
			const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
			const value =
				Number(ethBalance?.data?.formatted) * bnbUsdtPriceData.price;
			handleTransfer(id, (usdtPrice / bnbUsdtPriceData.price).toString());
			if (value > usdtPrice) {
			} else {
			}
		} catch (error) {
			console.error("Error fetching BNB/USDT price:", error);
		}
	};
	const handleTransfer = async (id: number, amount: string) => {
		try {
			const isWrongChain =
				accountWagmi?.chainId !== selectedOption!.chainId;
			if (isWrongChain) {
				await switchChainAsync({ chainId: selectedOption!.chainId });
			}
			const hash = await writeContractAsync({
				abi:
					selectedOption!.chainId === 8453
						? abi
						: selectedOption!.chainId === 56
						? abiBSC
						: abiARB,
				address:
					selectedOption!.chainId === 8453
						? SHOP_WALLET_CONTRACT_ADDRESS_BASE
						: selectedOption!.chainId === 56
						? SHOP_WALLET_CONTRACT_ADDRESS_BSC
						: SHOP_WALLET_CONTRACT_ADDRESS_BRB,
				functionName: "deposit",
				value: parseEther(amount),
				args: [id],
			});
			await publicClient?.waitForTransactionReceipt({
				hash: hash,
			});
			setPaymentSuccess(true);
			setShowCheckIn(
				"Payment successful. Reopen the game and check Mailbox to claim items"
			);
		} catch (e: any) {
			console.log({ e });
			setShowCheckIn(e.cause.shortMessage);
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCheckIn(null);
		}, 5000);
		return () => clearTimeout(timer);
	}, [showCheckIn]);

	return (
		<div className={style.main}>
			<div className={style.body}>
				<img src={paymentSuccess} alt="SlimeImg" width={200} />
				<SelectChain
					onSelect={async (value: OptionSelectChain) => {
						setSelectedOption(value);
						await switchChainAsync({ chainId: value.chainId });
					}}
				/>
				<p className={style.titleStyle}>
					{isPaymentSuccess ? "Payment successful" : "Confirm Purchase"}
				</p>
				{!isPaymentSuccess ? (
					<div className={style.infoCard}>
						<ul>
							<li>
								Package: +{initata?.Amount} Spin Enegry (x2 for
								1st time purchase)
							</li>
							<li>Price: ${initata?.price}</li>
						</ul>
					</div>
				) : (
					<p className={style.paymentSuccessStyle}>
						Please reopen the game and check Mailbox to claim items
					</p>
				)}
				{/* {showCheckIn !== null && (
					<div className={style.notice1}>{showCheckIn}</div>
				)} */}
				{!isPaymentSuccess && (
					<GameButton
						title="Confirm"
						isDisable={selectedOption === null ? true : false}
						onTap={() => {
							if (selectedOption !== null) {
								buyItem(initata!.id!, initata!.price!);
							}
						}}
					/>
				)}
			</div>
		</div>
	);
}
